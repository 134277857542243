<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :options="options"
    :clearable="clearable"
    :disabled="readonly"
    :loading="loading"
    :value="value"
    :label="selectionLabel"
    :reduce="(item) => (reduce ? item[selectionKey] : item)"
    @input="input"
  >
    <template #spinner="{ loading }">
      <b-spinner
        v-if="loading"
        variant="primary"
        label="Spinning"
      />
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    value: {},
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    reduce: {
      type: Boolean,
      default: false,
    },
    selectionKey: {
      type: String,
      default: 'id',
    },
    selectionLabel: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      option: [],
    }
  },
  methods: {
    input(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
