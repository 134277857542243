<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            v-if="$can('update', 'player')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mb-1"
            :to="{ name: 'update-player', params: { id: $route.params.id } }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("button.update") }}
          </b-button>
          <b-button
            v-if="$can('create', 'player')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mb-1 ml-1"
            :to="{ name: 'create-player', query: { id: $route.params.id } }"
          >
            <feather-icon icon="CopyIcon" />
            {{ $t("button.clone") }}
          </b-button>
          <b-button
            v-if="
              data.parentId === userId &&
                ($can('create', 'player') || $can('edit', 'player')) && !data.isVendor
            "
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mb-1 ml-1"
            @click="showDepositOrWithdrawModel"
          >
            <feather-icon icon="CreditCardIcon" />
            {{ $t("button.deposit") }}
          </b-button>
        </b-col>
      </b-row>
      <n-input-view
        v-model="data"
        :fields="fields"
        :init-value="initData"
      >
        <template #loginTimeout="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-single-select
              v-model="data[item.field.key]"
              :reduce="true"
              :options="timeout"
              :clearable="false"
              :readonly="true"
            />
          </b-form-group>
        </template>
      </n-input-view>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <deposit-or-withdraw
      ref="depositWithdrawModel"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BFormGroup, BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NInputView from '@/components/NInputView.vue'
import DepositOrWithdraw from '@/views/account/depositOrWithdraw.vue'
import NSingleSelect from '@/components/NSingleSelect.vue'
import Timeout from '@/data/timeout'
import { externalFields, internalUpdateFields } from './formInput'

const PlayerRepository = Repository.get('player')

export default {
  components: {
    BFormGroup,
    NSingleSelect,
    BButton,
    BRow,
    BCol,
    BCard,
    NInputView,
    DepositOrWithdraw,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        firstName: null,
        lastName: null,
        image: null,
        gender: 'male',
        username: null,
        email: null,
        password: null,
        parentId: null,
      },
      initData: {},
      loading: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem('userData'))
    },
    userId() {
      if (this.$store.state.profile.id) {
        return this.$store.state.profile.id
      }

      return this.userData?.id
    },
    fields() {
      if (this.data.isVendor) {
        return [...externalFields]
      }

      return [...internalUpdateFields]
    },
  },
  mounted() {
    this.show()
  },
  methods: {
    show() {
      PlayerRepository.show(this.$route.params.id).then(response => {
        if (response?.data?.data) {
          this.initData = {
            ...response.data.data,
          }
        }
      })
    },
    showDepositOrWithdrawModel(username) {
      this.$refs.depositWithdrawModel.changeData({
        type: 'deposit',
        username: this.data.username,
        amount: 0,
        userId: this.$route.params.id,
        remark: null,
      })

      this.$refs.depositWithdrawModel.show(
        `${this.$t('field.deposit')} (${username})`,
      )
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const timeout = [...Timeout]
    return { timeout }
  },
}
</script>
