<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="createForm"
        #default="{ invalid }"
      >
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          :form="$refs.createForm"
          :disabled="invalid || checkUsernameLoading"
          @submit="submit"
        >
          <n-input
            v-model="data"
            :fields="fields"
            :init-value="initData"
          >
            <template #parentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :init-options="data[item.field.key]"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :readonly="getSuggestionUsernameLoading"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    @optionChange="changeOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #loginTimeout="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :reduce="true"
                    :options="timeout"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <n-button-loading
                v-if="$can('create', 'player')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid || checkUsernameLoading"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { BCard, BCol, BFormGroup, BRow, } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading.vue'
import NFormConfirmation from '@/components/NFormConfirmation.vue'
import NSingleSelect from '@/components/NSingleSelect.vue'
import NInput from '@/components/NInput.vue'
import Alphabet from '@/data/numberAlphabet'
import Timeout from '@/data/timeout'
import NAsyncSingleSelect from '@/components/NAsyncSingleSelect.vue'
import { internalCreateFields } from './formInput'

const PlayerRepository = Repository.get('player')

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    NAsyncSingleSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        firstName: null,
        lastName: null,
        image: null,
        gender: 'male',
        username: null,
        email: null,
        password: null,
        roles: [],
        perBet: 0,
        todayBet: 0,
        givenCredit: 0,
        commission: 0,
        share: 0,
        ccy: null,
        isEnable: true,
        parentId: null,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      getSuggestionUsernameLoading: false,
      username: {
        parent: null,
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.parentId': function (value) {
      this.getSuggestUsername(value)
    },
    // eslint-disable-next-line func-names
    'data.username': function (value) {
      if (value) {
        this.checkUsername(`${this.username.parent}${value}`)
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.show()
    }
  },
  methods: {
    changeOption(value) {
      this.username.parent = value?.username
    },
    show() {
      PlayerRepository.show(this.$route.query.id).then(response => {
        delete response.data.data.firstName
        delete response.data.data.lastName
        delete response.data.data.phone
        delete response.data.data.email
        delete response.data.data.username
        delete response.data.data.profilePicture

        this.initData = {
          ...response.data.data,
        }
      })
    },
    submit() {
      if (!this.usernameValid) {
        return
      }
      this.$refs.createForm.validate().then(success => {
        if (success) {
          this.loading = true
          const data = {
            ...this.data,
            username: `${this.username.parent}${this.data.username}`,
          }
          PlayerRepository.create(data)
            .then(response => {
              this.$router.push({
                name: 'view-player',
                params: { id: response.data.data.id },
              })
            })
            .catch(error => {
              if (error.response?.status === 422) {
                this.$refs.createForm.setErrors(error.response?.data?.errors)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    getSuggestUsername(parentId) {
      this.checkUsernameLoading = true
      this.getSuggestionUsernameLoading = true
      PlayerRepository.getSuggestUsername(parentId)
        .then(response => {
          const data = response.data?.data
          const { username } = data
          this.data.username = username.replace(data.parentUsername, '')
          this.usernameValid = true
        })
        .catch()
        .then(() => {
          this.checkUsernameLoading = false
          this.getSuggestionUsernameLoading = false
        })
    },
    checkUsername(username) {
      if (this.getSuggestionUsernameLoading) {
        return
      }

      this.checkUsernameLoading = true
      PlayerRepository.checkUsername(username)
        .then(response => {
          if (response.data?.data.used) {
            this.$refs.createForm.setErrors({
              username: this.$t('validation.unique', {
                field: this.$t('field.username'),
              }),
            })
            this.usernameValid = false
          } else {
            this.usernameValid = true
          }
        })
        .catch()
        .then(() => {
          this.checkUsernameLoading = false
        })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = internalCreateFields.map(value => {
      if (value.key === 'password' || value.key === 'retypePassword') {
        value.rules = value.rules.replace('required|', '')
        value.rules = value.rules.replace('required', '')
        value.rules = value.rules.replace('', 'required|')
      } else if (value.key === 'username') {
        value.disabled = false
      }

      return value
    })

    const alphabet = [...Alphabet]
    const timeout = [...Timeout]
    return { fields, alphabet, timeout }
  },
}
</script>
