<template>
  <v-select
    :value="loadedInitOptions ? value : null"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :label="selectionLabel"
    :disabled="readonly"
    :placeholder="placeholder"
    :options="option"
    :loading="loading"
    :clearable="clearable"
    :reduce="(item) => (reduce ? item[selectionKey] : item)"
    @search="getOption"
    @open="open"
    @input="input"
  >
    <template #no-options>
      {{ $t("selection.noOption") }}
    </template>
    <template #spinner="{ loading }">
      <b-spinner
        v-if="loading"
        variant="primary"
        label="Spinning"
      />
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import { BSpinner } from 'bootstrap-vue'

import RepositoryFactory from '@/repositories/RepositoryFactory'

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    repository: {},
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Boolean,
      default: true,
    },
    selectionKey: {
      type: String,
      default: 'id',
    },
    selectionLabel: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      option: [],
      Repository: null,
      loading: false,
      loadedInitOptions: false,
    }
  },
  watch: {
    value(value) {
      if (!this.loadedInitOptions && value) {
        this.getOption()
      }
    },
  },
  created() {
    this.Repository = RepositoryFactory.get(this.repository)
  },
  methods: {
    input(value) {
      this.$emit('input', value)
      this.optionChange(value)
    },
    optionChange(value) {
      const index = this.option.findIndex(item => item[this.selectionKey] === value)

      this.$emit('optionChange', this.option[index])
    },
    open() {
      if (!this.option.length) {
        this.getOption()
      }
    },
    mapExcludeIds() {
      const excludeIds = [...this.option]

      return excludeIds.map(item => item[this.selectionKey])
    },
    mergeArray(arrayA, arrayB) {
      return arrayA.concat(arrayB).reduce((accumulator, currentValue) => {
        if (
          !accumulator.some(item => item[this.selectionKey] === currentValue[this.selectionKey])
        ) {
          accumulator.push(currentValue)
        }

        return accumulator
      }, [])
    },
    getOption(search = '') {
      this.loading = true
      const excludeIds = this.mapExcludeIds()

      this.Repository.options({
        limit: 10,
        offset: 0,
        search,
        includeIds: this.loadedInitOptions ? '' : this.value,
        excludeIds: this.loadedInitOptions ? excludeIds.join(',') : '',
      })
        .then(response => {
          if (response?.data?.data?.list) {
            this.option = this.mergeArray(this.option, response.data.data.list)
          } else {
            this.option = this.mergeArray(this.option, response.data.data)
          }

          if (!this.loadedInitOptions) {
            this.optionChange(this.value)
            this.loadedInitOptions = true
          }
        })
        .catch()
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
