<template>
  <b-card no-body>
    <b-card-header
      v-if="showHeader"
      v-b-toggle.search
      class="cursor-pointer"
    >
      <b-row class="w-100">
        <b-col>
          {{ $t("general.search") }}
        </b-col>
        <b-col>
          <feather-icon
            v-if="!visible"
            v-b-tooltip.hover
            icon="ChevronDownIcon"
            size="16"
            class="float-right"
          />
          <feather-icon
            v-if="visible"
            v-b-tooltip.hover
            icon="ChevronUpIcon"
            size="16"
            class="float-right"
          />
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse
      id="search"
      v-model="visible"
      class="m-2"
    >
      <slot />
      <div class="float-right w-100">
        <b-row
          v-if="showSearch"
          align-h="end"
        >
          <b-col
            md="8"
            lg="4"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                :placeholder="$t('general.search')"
              />

              <b-input-group-prepend>
                <b-button
                  v-shortkey="['ctrl', 'enter']"
                  variant="primary"
                  class="mr-2"
                  @click="search"
                  @shortkey="search"
                >
                  {{ $t("general.search") }}
                </b-button>
                <b-button
                  v-shortkey="['ctrl', 'esc']"
                  variant="outline-secondary"
                  @click="reset"
                  @shortkey="reset"
                >
                  {{ $t("general.reset") }}
                </b-button>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col class="text-right">
            <b-button
              v-shortkey="['ctrl', 'enter']"
              variant="primary"
              class="mr-1"
              @click="search"
              @shortkey="search"
            >
              {{ $t("general.search") }}
            </b-button>
            <b-button
              v-shortkey="['ctrl', 'esc']"
              variant="outline-secondary"
              @click="reset"
              @shortkey="reset"
            >
              {{ $t("general.reset") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BCollapse,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  VBToggle,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BCollapse,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    variant: {},
    type: {},
    value: {},
    loading: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: this.active,
      searchText: '',
    }
  },
  mounted() {
    if (this.value?.search) {
      this.searchText = this.value.search;
    }
  },
  methods: {
    search() {
      this.$emit('search', this.searchText)
    },
    reset() {
      this.searchText = ''
      this.$emit('reset')
    },
  },
}
</script>

<style scoped>
</style>
